import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/auth/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import '../styles/App.css';

function Login() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState(Array(6).fill('')); // 6-digit code as an array
    const [step, setStep] = useState(1);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    const inputRefs = useRef([]);

    const formatPhoneNumber = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            return !match[2] ? match[1] : `${match[1]}-${match[2]}${match[3] ? `-${match[3]}` : ''}`;
        }
        return value;
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(formatPhoneNumber(e.target.value));
    };

    const handleCodeChange = (e, index) => {
        const newCode = [...code];
        newCode[index] = e.target.value.slice(-1); // Only allow one digit
        setCode(newCode);

        // Move focus to the next input field if the current input has a value
        if (e.target.value && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        // Automatically submit when all digits are filled
        if (newCode.every((digit) => digit !== '') && index === 5) {
            verifyCode(newCode.join(''));
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && code[index] === '' && index > 0) {
            // Move focus to the previous input field when backspacing
            inputRefs.current[index - 1].focus();
        }
    };

    const requestVerification = async () => {
        const cleanedPhoneNumber = phoneNumber.replace(/-/g, '');

        try {
            await axios.post('https://ksheth.herokuapp.com/request-verification', { phone_number: cleanedPhoneNumber });
            setStep(2);
            setError('');
        } catch (err) {
            setError('Failed to send verification code. Please try again.');
        }
    };

    const verifyCode = async (code) => {
        const cleanedPhoneNumber = phoneNumber.replace(/-/g, '');
        try {
            const response = await axios.post('https://ksheth.herokuapp.com/verify-code', { phone_number: cleanedPhoneNumber, code });
            const jwt = response.data.access_token;
            const user = response.data.user;
            login(jwt, user);
            setError('');
            navigate('/dashboard');
        } catch (err) {
            setError('Invalid verification code. Please try again.');
        }
    };

    return (
        <div className='container container-page' style={{ textAlign: 'center' }}>
            <div className='modal-container'>
                {step === 1 && (
                    <div className='modal-login'>
                        <h1><FontAwesomeIcon icon={faLock} /></h1>
                        <div>
                            <p>Enter your phone number</p>
                            <p className='subtext'>You will receive a 6-digit code to verify your identity.</p>
                        </div>
                        <div className='phone-input-group'>
                            <span className='country-code'>+1</span>
                            <input
                                type="tel"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                placeholder="###-###-####"
                                className='input-lg'
                                maxLength="12"
                            />
                            <button onClick={requestVerification} className='button'><FontAwesomeIcon icon={faPaperPlane} /></button>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div className='modal-login'>
                        <h1><FontAwesomeIcon icon={faLock} /></h1>
                        <p>Enter the verification code</p>
                        <div className="code-input-group">
                            {code.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    value={digit}
                                    onChange={(e) => handleCodeChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    className="input-lg code-input"
                                    maxLength="1"
                                    style={{ width: '40px', textAlign: 'center' }}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        </div>
    );
}

export default Login;
