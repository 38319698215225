import React from "react"
import PhotoTile from "./PhotoTile"
import {useState} from 'react'
import Lightbox from "./Lightbox/Lightbox"
import PhotoFilter from "../filtering/PhotoFilter"

const PhotoGallery = (props) => {
    const [selectedPhoto, setSelectedPhoto] = useState()
    const [filteredPhotos, setFilteredPhotos] = useState(props.photos)

    const applyFilters = (filters)=> {
        const updatedPhotos = props.photos.filter((photo) => {
            return (filters["camera"].has(photo["camera"]) 
            && filters["colors"].has(photo["hueBucket"]) 
            && filters["aperture"].has(photo["apertureBucket"])
            && filters["shutterspeed"].has(photo["shutterspeedBucket"]))
        })
        setFilteredPhotos(updatedPhotos)
    }

    return (
        <div>
            <div className="container photo-gallery-grid">
                {filteredPhotos.length > 0 ? filteredPhotos.map((photo) => {
                    return (
                        <PhotoTile key={photo.id} photo={photo} onClick={() => setSelectedPhoto(photo)} />
                    )
                }) : "No photos with these properties."}
                {selectedPhoto && <Lightbox photo={selectedPhoto} onClose={() => setSelectedPhoto(null)} />}
            </div>
            <PhotoFilter applyFilters={applyFilters}/>
        </div>
        
    )
}

export default PhotoGallery

// Lightweight photo gallery without filtering
export const PhotoGalleryLite = (props) => {
    const [selectedPhoto, setSelectedPhoto] = useState()

    return (
        <div>
            <div className="container photo-gallery-grid">
                {props.photos.map((photo) => {
                    return (
                        <PhotoTile key={photo.id} photo={photo} onClick={() => setSelectedPhoto(photo)} />
                    )
                })}
                {selectedPhoto && <Lightbox photo={selectedPhoto} onClose={() => setSelectedPhoto(null)} />}
            </div>
        </div>
    )
}