import React, { useState, useEffect, useContext } from 'react';
import '../styles/App.css';
import axios from 'axios';
import { AuthContext } from '../components/auth/AuthProvider';

const Dashboard = () => {
    const { user } = useContext(AuthContext);  // Access the AuthContext
    const [loading, setLoading] = useState(true);  // State to handle loading
    const [error, setError] = useState(null);  // State to handle errors

    useEffect(() => {
        const fetchUserData = async () => {
            const jwt = localStorage.getItem('jwt');  // Retrieve the JWT from localStorage
            try {
                const response = await axios.get('https://ksheth.herokuapp.com/dashboard', {
                    headers: {
                        Authorization: `Bearer ${jwt}`  // Include the JWT in the Authorization header
                    }
                });

                // If successful, set the user data
                console.log(response.data);

                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setError('Unauthorized. Please log in again.');
                } else {
                    setError('An error occurred while fetching data.');
                }
                setLoading(false);
            }
        };

        fetchUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='container container-page'>
      Welcome, {user.first_name}.
    </div>
  );
}

export default Dashboard;