import React from 'react'

const ColorSwatches = (props) => {

    const hexCodes = props.hexCodes.replaceAll('\'', '').replaceAll('[', '').replaceAll(']', '').split(', ')

    return (
        <div className='color-swatches'> 
            {hexCodes.map((hexCode) => {
                return (
                    <div className='color-swatch' style={{backgroundColor: hexCode}}></div>
                )
            })}
            
        </div>
    )
}

export default ColorSwatches