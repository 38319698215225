import React from 'react';
import { Link } from "react-router-dom"
import '../../styles/App.css';
import LocationMetadata from './LocationMetadata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';

const LocationTile = ({ location, onClick, isSelected, markerId }) => {

    return (
        <div className={isSelected ? 'location-tile active' : 'location-tile'} onClick={(e) => (onClick ? onClick(e, location) : null)}>
            <div className='location-tile-content'>
                <div className='location-title'>{ location.title }</div>
                <LocationMetadata location={location} />
            </div>
            {location.has_photos ? 
                <div className='location-tile-button'>
                    <Link to={"/location/" + location.id} className="tile tile-album">
                        <FontAwesomeIcon icon={faImages} />
                    </Link>
                </div>
            : null}
            
            
        </div>
    )
}

export default LocationTile