import React, { useEffect } from "react"
import '../../../styles/App.css'
import config from '../../../config.json'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import ColorSwatches from '../ColorSwatches'
import Histogram from '../Histogram'

const Lightbox = (props) => {

    // Prevent body scroll when lightbox is open
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
     }, []);

    return (
        <div className="container container-modal">
            <div className="modal-header" onClick={props.onClose}>
                <button className="button"><FontAwesomeIcon icon={faXmark} /></button>
            </div>
            <div className="modal-body">
                <div className="lightbox-image-container">
                    <img className="lightbox-image" src={config.s3_url + '/' + props.photo.path + '/' + props.photo.file_name} alt="Lightbox" />
                </div>
                <div className="lightbox-info">
                    <table className="lightbox-table">
                        <tbody>
                            <tr>
                                <td>Camera</td>
                                <td>
                                <img className="camera-image" src={config.s3_url + '/' + props.photo.cameraIcon} alt="Camera Icon" />
                                </td>
                            </tr>
                            <tr>
                                <td>Aperture</td>
                                <td>f {eval(props.photo.aperture)}</td>
                            </tr>
                            <tr>
                                <td>Shutterspeed</td>
                                <td>{props.photo.shutterspeed} seconds</td>
                            </tr>
                            <tr>
                                <td>ISO</td>
                                <td>{props.photo.iso}</td>
                            </tr>
                            <tr>
                                <td>Program</td>
                                <td>{props.photo.program}</td>
                            </tr>
                            <tr>
                                <td>Focal Length</td>
                                <td>{props.photo.focal_length} mm</td>
                            </tr>
                            <tr>
                                <td>Dominant Colors</td>
                                <td>
                                    <ColorSwatches hexCodes={props.photo.dominant_colors} />
                                </td>
                            </tr>
                            <tr>
                                <td>Highlight Colors</td>
                                <td>
                                    <ColorSwatches hexCodes={props.photo.highlight_colors} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <Histogram rgbArray={JSON.parse(props.photo.rgb_array)}/>
                    {/* <Histogram data={data}/> */}
                </div>
            </div>
        </div>
    )
}

export default Lightbox