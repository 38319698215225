import React from "react"
import {useState, useContext} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSliders, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FilterContext } from "../../pages/Gallery"

const PhotoFilter = (props) => {
    const [cameras, colorBuckets, apertureBuckets, shutterspeedBuckets] = useContext(FilterContext)

    const [isOpen, setIsOpen] = useState(0);

    // filter template
    const filtersOff = {
        "camera": new Set(),
        "colors": new Set(),
        "aperture": new Set(),
        "shutterspeed": new Set()
    }

    const [filters, setFilters] = useState(filtersOff);

    const toggleFilter = (filterType, filterValue) => {
        let updatedFilters = { ...filters }
        if (filters[filterType].has(filterValue)) {
            updatedFilters[filterType].delete(filterValue)
        } else {
            updatedFilters[filterType].add(filterValue)
        }
        setFilters(updatedFilters)
    }

    const handleFilterApply = () => {
        let filtersToApply = structuredClone(filters)
        // if filter has no selected values, add all
        if (filtersToApply['camera'].size === 0) {
            cameras.forEach(b => filtersToApply['camera'].add(b.id))
        }
        if (filtersToApply['colors'].size === 0) {
            colorBuckets.forEach(b => filtersToApply['colors'].add(b.centroid))
        }
        if (filtersToApply['aperture'].size === 0) {
            apertureBuckets.forEach(b => filtersToApply['aperture'].add(b.lower))
        }
        if (filtersToApply['shutterspeed'].size === 0) {
            shutterspeedBuckets.forEach(b => filtersToApply['shutterspeed'].add(b.lower))
        }
        props.applyFilters(filtersToApply)
        setIsOpen(0);
    }

    return (
        <div>
            <button className="button button-filter" onClick={() => setIsOpen(1)}><FontAwesomeIcon icon={faSliders} />Filter</button>
            {isOpen ? 
                <div className="container container-modal">
                    <div className="modal-header">
                        <button className="button" onClick={() => setIsOpen(0)}><FontAwesomeIcon icon={faXmark} /></button>
                    </div>
                    <div className="modal-body modal-body-filters">
                        <h1>Filter by</h1>
                        <div className="filter-group">
                            <h5>Camera</h5>
                            <div className="filter-group-buttons">
                                {cameras && cameras.map((camera) => {
                                    return(
                                        <button key={camera.id} className={filters["camera"].has(camera.id) ? "button filter-button selected" : "button filter-button"} onClick={() => {toggleFilter("camera", camera.id)}}>{camera.model}</button>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="filter-group">
                            <h5>Color</h5>
                            <div className="filter-group-buttons">
                                {colorBuckets.map((color) => {
                                    return(
                                        <button key={color.centroid} className={filters["colors"].has(color.centroid) ? "button filter-button filter-button-color selected" : "button filter-button filter-button-color"} 
                                            style={{backgroundColor:color.hsl}} 
                                            onClick={() => {toggleFilter("colors", color.centroid)}}>
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="filter-group">
                            <h5>Aperture</h5>
                            <div className="filter-group-buttons">
                                {apertureBuckets.map((bucket) => {
                                    return(
                                        <button key={bucket.lower} className={filters["aperture"].has(bucket.lower) ? "button filter-button selected" : "button filter-button"} onClick={() => {toggleFilter("aperture", bucket.lower)}}>{bucket.label}</button>
                                    )
                                })}
                            </div>
                            <h5>Shutterspeed</h5>
                            <div className="filter-group-buttons">
                                {shutterspeedBuckets.map((bucket) => {
                                    return(
                                        <button key={bucket.lower} className={filters["shutterspeed"].has(bucket.lower) ? "button filter-button selected" : "button filter-button"} onClick={() => {toggleFilter("shutterspeed", bucket.lower)}}>{bucket.label}</button>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="filter-group">
                            <button className="button button-filter-apply" onClick={() => handleFilterApply()}>Apply</button>
                        </div>
                    </div>
                </div>
            : null}
        </div>
    )
}

export default PhotoFilter