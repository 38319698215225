import React from 'react'
import '../styles/App.css'
import {useState, useEffect, createContext} from 'react'
import AlbumGallery from '../components/content/AlbumGallery'
import PhotoGallery from '../components/content/PhotoGallery'

export const FilterContext = createContext(null)

const colorBuckets = [
  { centroid: 0, hsl: 'hsl(0, 80%, 55%)'},
  { centroid: 45, hsl: 'hsl(45, 80%, 55%)'},
  { centroid: 90, hsl: 'hsl(90, 80%, 55%)'},
  { centroid: 135, hsl: 'hsl(135, 80%, 55%)'},
  { centroid: 180, hsl: 'hsl(180, 80%, 55%)'},
  { centroid: 225, hsl: 'hsl(225, 80%, 55%)'},
  { centroid: 270, hsl: 'hsl(270, 80%, 55%)'},
  { centroid: 315, hsl: 'hsl(315, 80%, 55%)'}
]

const apertureBuckets = [
  {lower: 0, upper: 3.0, label: '<f3'},
  {lower: 3.0, upper: 6.0, label: 'f3-f6'},
  {lower: 6.0, upper: 9.0, label: 'f6-f9'},
  {lower: 9.0, upper: 12.0, label: 'f9-f12'},
  {lower: 12.0, upper: 100.0, label: '>f12'}
]

const shutterspeedBuckets = [
  {lower: 0.0001, upper: 0.001, label: '< 1/1000s'},
  {lower: 0.001, upper: 0.01, label: '1/000s - 1/100s'},
  {lower: 0.01, upper: 0.1, label: '1/100s - 1/10s'},
  {lower: 0.1, upper: 1.0, label: '1/10s - 1s'},
  {lower: 1.0, upper: 100.0, label: '> 1s'}
]

function Gallery() {

  const [photos, setPhotos] = useState([])
  const [albums, setAlbums] = useState([])
  const [cameras, setCameras] = useState([])
  const [showAllPhotos, setShowAllPhotos] = useState(0)

  useEffect(() => {
    Promise.all([
      fetch("https://ksheth.herokuapp.com/getAllPhotos").then(value => value.json()),
      fetch("https://ksheth.herokuapp.com/getAllAlbums").then(value => value.json()),
      fetch("https://ksheth.herokuapp.com/getCameras").then(value => value.json())
    ]).then(([photos, albums, cameras]) => {
      const photosWithAttributes = photos.map(obj => {
        let apertureBucket, shutterspeedBucket
        if (obj.aperture) {
          apertureBucket = apertureBuckets.find((b) => fractionToFloat(obj.aperture) >= b.lower && fractionToFloat(obj.aperture) < b.upper)
        }
        if (obj.shutterspeed) {
          shutterspeedBucket = shutterspeedBuckets.find((b) => fractionToFloat(obj.shutterspeed) >= b.lower && fractionToFloat(obj.shutterspeed) < b.upper)
        }

        return { 
          ...obj,
          hueBucket: extractHue(obj.highlight_colors.replaceAll('\'', '').replaceAll('[', '').replaceAll(']', '').split(', ')[0]),
          apertureBucket: apertureBucket ? apertureBucket.lower : null,
          shutterspeedBucket: shutterspeedBucket ? shutterspeedBucket.lower : null,
        }
      })
      setPhotos(photosWithAttributes)
      setAlbums(albums)
      setCameras(cameras)
    }).catch((err) => {
      console.log(err)
    });
  }, [])

  return (
    <FilterContext.Provider value={[cameras, colorBuckets, apertureBuckets, shutterspeedBuckets]}>
      <div className='container container-page'>
        <div className="container container-subnav">
          <div className='subnav-left'>
            <button className={showAllPhotos ? 'button' : 'button active'} onClick={() => setShowAllPhotos(0)}>Albums</button>
            <button className={showAllPhotos ? 'button active' : 'button'} onClick={() => setShowAllPhotos(1)}>All Photos</button>
          </div>
        </div>
        <div>
          {showAllPhotos ? <PhotoGallery photos={photos} /> : <AlbumGallery albums={albums} />}
        </div>
      </div>
    </FilterContext.Provider>

  );
}

// Helper functions
function extractHue(hex) {
  // Remove the # symbol, if present
  hex = hex.replace(/#/g, '');

  // Parse the hex string into RGB values
  const r = parseInt(hex.slice(0, 2), 16) / 255;
  const g = parseInt(hex.slice(2, 4), 16) / 255;
  const b = parseInt(hex.slice(4, 6), 16) / 255;

  // Find the maximum and minimum values for RGB
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let delta = max - min; // Define delta here

  // Calculate the hue
  let hue;
  if (max === min) {
      hue = 0;
  } else {
      switch (max) {
          case r:
              hue = (60 * ((g - b) / delta) + 360) % 360;
              break;
          case g:
              hue = (60 * ((b - r) / delta) + 120) % 360;
              break;
          case b:
              hue = (60 * ((r - g) / delta) + 240) % 360;
              break;
      }
  }

  let h = Math.round(hue);
  let buckets = colorBuckets.map(e => e.centroid)
  let closestValue = buckets[0]; // Initialize closestValue with the first element of the array
  let minDifference = Math.abs(h - buckets[0]); // Initialize minDifference with the absolute difference

  for (let i = 1; i < buckets.length; i++) {
      const difference = Math.abs(h - buckets[i]);
      if (difference < minDifference) {
          minDifference = difference;
          closestValue = buckets[i];
      }
  }

  return closestValue;
}

function fractionToFloat(fractionString) {
  if (fractionString.includes('/')) {
    return parseFloat(fractionString.split('/')[0]) / parseFloat(fractionString.split('/')[1])
  } else {
    return parseFloat(fractionString)
  }
}

export default Gallery
