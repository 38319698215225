import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import BackButton from '../components/navigation/BackButton'
import LocationMetadata from '../components/content/LocationMetadata'
import '../styles/App.css'
import { PhotoGalleryLite } from '../components/content/PhotoGallery'

function Location() {
  const { id } = useParams()
  const [location, setLocation] = useState({})
  const [locationPhotos, setLocationPhotos] = useState([])

  useEffect(() => {
    Promise.all([
      fetch("https://ksheth.herokuapp.com/getLocation/" + id).then(value => value.json()),
      fetch("https://ksheth.herokuapp.com/getPhotosByLocation/" + id).then(value => value.json())
    ]).then(([location, locationPhotos]) => {
      setLocation(location)
      setLocationPhotos(locationPhotos)
    }).catch((err) => {
      console.log(err)
    });
  }, [id])

  return (
    <div className='container container-page'>
      <div className='container container-subnav'>
        <div className="subnav-left">
          <BackButton toLink="/places" toName="Map" />
        </div>
      </div>
      <h1>{location.title}</h1>
      <div className='metadata' style={{marginBottom: '20px'}}>
        <LocationMetadata location={location} />
      </div>
      <PhotoGalleryLite photos={locationPhotos} />
    </div>
  );
}

export default Location;
