import React from 'react';
import '../../styles/App.css';

const LocationMetadata = ({location}) => {
    
    return (
        <div className='location-metadata'>
            {/\S/.test(location.city) && <div className='metadata-item location-city'>{ location.city }</div>}
            {/\S/.test(location.state_name) && <div className='metadata-item location-state'>{ location.state_name }</div>}
            {/\S/.test(location.country_name) && <div className='metadata-item location-country'>{ location.country_name }</div>}
        </div>
    )
}

export default LocationMetadata