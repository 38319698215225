import React from "react"
import AlbumTile from "./AlbumTile"

const AlbumGallery = (props) => {

    let yearAlbumDict = {}
    for (let i = 0; i < props.albums.length; i++) {
        let albumYear = new Date(props.albums[i].created).getFullYear()
        if (albumYear in yearAlbumDict) {
            yearAlbumDict[albumYear].push(props.albums[i])
        } else {
            yearAlbumDict[albumYear] = [props.albums[i]]
        }
    }

    return (
        <div className="container container-album-gallery">
            {Object.keys(yearAlbumDict).reverse().map((year) => {
                    return (
                        <div key={year}>
                            <h1 className="album-year">{year}</h1>
                            <div className="album-gallery">
                                {yearAlbumDict[year].map((album) => {
                                    return (
                                        <AlbumTile key={album.id} album={album} />
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            <div className="album-gallery">
                
            </div>
        </div>
    )
}

export default AlbumGallery