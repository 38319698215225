import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import environmentImage from '../../static/images/environmentMaps/map.hdr';
import lighterModel from '../../static/images/assets/lighter.glb';

const ThreeScene = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        const mount = mountRef.current;
        
        // Set up the scene
        const scene = new THREE.Scene();
    
        // Set up the camera
        const aspect = mount.clientWidth / mount.clientHeight;
        const d = 0.04;
        const camera = new THREE.OrthographicCamera(-d * aspect, d * aspect, d, -d, 1, 100);
        camera.position.set(0.4, 0.3, 1); // Set camera position for isometric view
        camera.lookAt(new THREE.Vector3(0, 0, 0));
    
        // Set up the renderer
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(mount.clientWidth, mount.clientHeight);
        renderer.toneMapping = THREE.ACESFilmicToneMapping; // Enable tone mapping
        renderer.toneMappingExposure = 0.5;
        mount.appendChild(renderer.domElement);

        // Set up OrbitControls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true; // Enable damping (inertia)
        controls.dampingFactor = 0.25; // Damping factor
        controls.screenSpacePanning = false; // Do not allow panning
        controls.enablePan = false;

        // Load environment file
        new RGBELoader().load(environmentImage, (environmentMap) => {
            environmentMap.mapping = THREE.EquirectangularReflectionMapping;
            scene.environment = environmentMap;

            // Render the scene once the environment map is loaded
            renderer.render(scene, camera);
        });

        // Light
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.2); // Increased intensity
        scene.add(ambientLight);

        const spotLight1 = new THREE.SpotLight(0xffffaa, 10, 0, Math.PI / 2.5);
        spotLight1.position.set(-.1, -.1, .2);
        scene.add(spotLight1);

        const spotLight2 = new THREE.SpotLight(0xffffaa, 10, 0, Math.PI / 2.5);
        spotLight2.position.set(.1, -.1, -.1);
        scene.add(spotLight2);

        const spotLight3 = new THREE.SpotLight(0xffffaa, 10, 0, Math.PI / 2.5);
        spotLight3.position.set(-.1, .2, -.1);
        scene.add(spotLight3);

        // Set up materials
        const goldMaterial = new THREE.MeshStandardMaterial({ 
            color: 0xffb700,
            roughness: 0,
            metalness: 1
        });

        // Load the model
        const loader = new GLTFLoader();
        loader.load(lighterModel, (gltf) => {
            const model = gltf.scene;
            // Traverse the model and apply the gold material to all meshes
            model.traverse((node) => {
                if (node.isMesh) {
                    node.material = goldMaterial;
                }
            });
            scene.add(model);
            renderer.render(scene, camera); // Render the scene after adding the model
        }, undefined, (error) => {
            console.error('An error happened', error);
        });

        // Add the striker
        const strikerGeometry = new THREE.CylinderGeometry(0.0035, 0.0035, .025, 32);
        const striker = new THREE.Mesh(strikerGeometry, goldMaterial);
        striker.position.x = 0.0076;
        striker.position.y = 0.0075;
        striker.position.z = 0.0010;

        // Add ridges to the striker
        const ridgeCount = 24;
        const ridgeHeight = .025;
        const ridgeWidth = .0003;
        const ridgeDepth = .0003;

        for (let i = 0; i < ridgeCount; i++) {
            const angle = (i / ridgeCount) * Math.PI * 2;
            const x = 0.0035 * Math.cos(angle);
            const z = 0.0035 * Math.sin(angle);
            const ridgeGeometry = new THREE.BoxGeometry(ridgeWidth, ridgeHeight, ridgeDepth);
            const ridge = new THREE.Mesh(ridgeGeometry, goldMaterial);
            ridge.position.set(x, 0, z);
            ridge.rotation.y = -angle + Math.PI/4;
            striker.add(ridge);
        }

        scene.add(striker);

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update(); // Update controls
            renderer.render(scene, camera);
        };

        animate();

        // Clean up on component unmount
        return () => {
            mount.removeChild(renderer.domElement);
        };
    }, []);
  
    return <div ref={mountRef} style={{ width: '100%', height: '70vh' }} />;
};

export default ThreeScene;
