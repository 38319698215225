import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from '../components/auth/AuthProvider';
import Home from './Home';
import Gallery from './Gallery';
import About from './About';
import Album from './Album';
import Places from './Places';
import Location from './Location';
import Login from './Login';
import Dashboard from './Dashboard';

const AppRoutes = () => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>
  }

  console.log(isAuthenticated)

  return (
    <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/places' element={<Places/>} />
        <Route path='/album/:id' element={<Album/>} />
        <Route path='/location/:id' element={<Location/>} />
        <Route path='/login' element={<Login/>} />
        <Route 
            path='/dashboard' 
            element={isAuthenticated ? <Dashboard/> : <Navigate to='/login'/>} 
        />
    </Routes>
  );
};

export default AppRoutes;