import React from 'react'
import { Link } from 'react-router-dom' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import '../../styles/App.css'

const BackButton = ({toLink, toName}) => {
    return (
        <Link to={toLink}>
            <button className='button'><FontAwesomeIcon icon={faArrowLeft} />{toName}</button>
        </Link>
    )
}

export default BackButton