import React, { useContext } from 'react';
import { AuthContext } from '../auth/AuthProvider';
import '../../styles/App.css';

const BarNav = () => {
    const { isAuthenticated, logout } = useContext(AuthContext);  // Access the AuthContext

    return (
        <div className="navbar navbar-expand">
            <div className="nav-group">
                <a href="/"><h3>Kishan</h3></a>
            </div>
            <div className="nav-group">
                <a href="/about" className="nav-item">About</a>
                <a href="/gallery" className="nav-item">Gallery</a>
                <a href="/places" className="nav-item">Places</a>
                {isAuthenticated ? 
                    <button className="nav-item button" onClick={logout}>Logout</button> :
                    <a href="/login" className="nav-item">Friends</a>}
            </div>
        </div>
    )
}

export default BarNav
