import React from "react"
import config from '../../config.json'

const PhotoTile = (props) => {
    return (
        <div className="tile tile-photo" onClick={props.onClick}>
            <img loading="lazy" className="tile-image" src={config.s3_url + '/' + props.photo.path + '/small/' + props.photo.file_name} />
        </div>
    )
}

export default PhotoTile