import React from "react"
import '../../styles/App.css'

const Marker = ({ location, lat, lng, markerId, onClick, isSelected, ...props }) => {
	return (
		<div
            className="map-marker"
			lat={lat}
			lng={lng}
			onClick={(e) => (onClick ? onClick(e, location) : null)}
			style={{ cursor: 'pointer', fontSize: 40}}
			alt={markerId}
			{...props}
		>
			{ isSelected ? <div className="marker-detail">{ location.title }</div> : ''}
		</div>
	)
}

export default Marker