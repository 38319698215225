import React from 'react'
import '../styles/App.css';
// import plane from '../static/images/plane.svg'
import ThreeScene from '../components/content/LighterBlender';

function Home() {
    return (
        <div className='container container-page'>
            <div className='container container-hero-image'>
                {/* <img className='hero-image' src={plane}/> */}
                <ThreeScene />
            </div>
        </div>
    );
}

export default Home;