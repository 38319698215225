import React, { useState } from "react";
import * as d3 from "d3";
import { _3d } from "d3-3d";
import { useD3 } from '../../hooks/useD3';
import { faChevronLeft, faChevronRight, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Histogram = ({ rgbArray }) => {
    const [startingYAngle, setStartingYAngle] = useState(Math.PI / 4);
    const [startingXAngle, setStartingXAngle] = useState(Math.PI / 8);

    const ref = useD3(
        (svg) => {
            const origin = [160, 170], j = 16, scale = 5, key = (d) => d.id, startYAngle = startingYAngle, startXAngle = startingXAngle;
            const svgG = svg.append('g');

            const grid3d = _3d()
                .shape('GRID', 32)
                .origin(origin)
                .rotateY(startYAngle)
                .rotateX(startXAngle)
                .scale(scale);

            const point3d = _3d()
                .x((d) => d.x)
                .y((d) => d.y)
                .z((d) => d.z)
                .origin(origin)
                .rotateY(startYAngle)
                .rotateX(startXAngle)
                .scale(scale);

            function processData(data, tt) {
                /* ----------- GRID ----------- */
                const xGrid = svgG.selectAll('path.grid').data(data[0], key);

                xGrid
                    .enter()
                    .append('path')
                    .attr('class', '_3d grid')
                    .merge(xGrid)
                    .attr('stroke', '#888888')
                    .attr('stroke-width', 0.3)
                    .attr('fill', (d) => d.ccw ? '#000000' : '#717171')
                    .attr('fill-opacity', 0.3)
                    .attr('d', grid3d.draw);

                xGrid.exit().remove();

                /* ----------- POINTS ----------- */
                const points = svgG.selectAll('circle').data(data[1], key);

                points
                    .enter()
                    .append('circle')
                    .attr('class', '_3d')
                    .attr('opacity', 0)
                    .attr('cx', posPointX)
                    .attr('cy', posPointY)
                    .merge(points)
                    .transition().duration(tt)
                    .ease(d3.easeLinear)
                    .attr('r', 1.5)
                    .attr('fill', (d) => `rgb(${(d.x + 16) * 8},${(d.y + 16) * 8},${(d.z + 16) * 8})`)
                    .attr('opacity', 1)
                    .attr('cx', posPointX)
                    .attr('cy', posPointY);

                points.exit().remove();
            }

            function posPointX(d) {
                return d.projected.x;
            }

            function posPointY(d) {
                return d.projected.y;
            }

            function init() {
                // Prepare Data
                const rgbs = rgbArray;
                let cnt = 0;
                const xGrid = [];
                const scatter = [];

                for (const rgb of rgbs) {
                    const r_value = Math.floor(rgb[0] / 8) - 16;
                    const g_value = Math.floor(rgb[1] / 8) - 16;
                    const b_value = Math.floor(rgb[2] / 8) - 16;
                    scatter.push({ x: r_value, y: g_value, z: b_value, id: 'point_' + cnt++ });
                }

                for (let z = -j; z < j; z++) {
                    for (let x = -j; x < j; x++) {
                        xGrid.push([x, 16, z]);
                    }
                }

                const data = [
                    grid3d(xGrid),
                    point3d(scatter),
                ];

                processData(data, 0);
            }

            init();
        },
        [rgbArray.length, startingXAngle, startingYAngle]  // Added startingAngle to dependencies
    );

    const rotateLeft = () => setStartingYAngle(startingYAngle + Math.PI / 8);
    const rotateRight = () => setStartingYAngle(startingYAngle - Math.PI / 8);
    const rotateUp = () => setStartingXAngle(startingXAngle - Math.PI / 8);
    const rotateDown = () => setStartingXAngle(startingXAngle + Math.PI / 8);

    return (
        <div className="histogram-container">
            <svg
                className="image-histogram"
                ref={ref}
                style={{
                    height: 350,
                    width: 350,
                    marginRight: "0px",
                    marginLeft: "0px",
                }}
            >
                <g className="plot-area"></g>
            </svg>
            <button className="button arrow-left" onClick={rotateLeft}>                
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button className="button arrow-up" onClick={rotateUp}>                
                <FontAwesomeIcon icon={faChevronUp} />
            </button>
            <button className="button arrow-down" onClick={rotateDown}>                
                <FontAwesomeIcon icon={faChevronDown} />
            </button>
            <button className="button arrow-right" onClick={rotateRight}>                
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    );
}

export default Histogram;