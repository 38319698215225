import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Check if there's a token in localStorage
    const token = localStorage.getItem('jwt');
    if (token) {
      setIsAuthenticated(true);
    }
    setLoading(false); // Set loading to false after checking
  }, []);

  const login = (jwt, activeUser) => {
    localStorage.setItem('jwt', jwt);
    setUser(activeUser)
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('jwt');
    setUser({})
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};