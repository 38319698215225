import React from 'react'
import BackButton from '../components/navigation/BackButton'
import {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import PhotoTile from "../components/content/PhotoTile"
import Lightbox from '../components/content/Lightbox/Lightbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapPin, faCamera, faCalendar, faTableCells, faTableList,  } from '@fortawesome/free-solid-svg-icons'

const Album = () => {
    const { id } = useParams()
    const [album, setAlbum] = useState(null)
    const [selectedPhoto, setSelectedPhoto] = useState()
    const [view, setView] = useState('list')

    useEffect(() => {
        fetch("https://ksheth.herokuapp.com/getAlbum/" + id)
        .then(response => response.json())
        .then(data => setAlbum(data))
    }, [id])

    return (
        <div className='container container-page'>
            <div className="container container-subnav">
                <div className="subnav-left">
                    <BackButton toLink="/gallery" toName="Albums" />
                </div>
                <div className="subnav-right">
                    <button className={view === 'list' ? 'button active' : 'button'} onClick={() => setView('list')}><FontAwesomeIcon icon={faTableList} /></button>
                    <button className={view === 'grid' ? 'button active' : 'button'} onClick={() => setView('grid')}><FontAwesomeIcon icon={faTableCells} /></button>
                </div>
            
            </div>
            {album && 
            <div className='container container-title'>
                <h1>{album.title}</h1>
                <p>{album.short_desc}</p>
                <div className='metadata'>
                    <div className='metadata-item'><FontAwesomeIcon icon={faCalendar} />{new Date(album.created).toISOString().split('T')[0]}</div>
                    <div className='metadata-item'>
                        <Link to={"/location/" + album.location_id} className='metadata-link'>
                            <FontAwesomeIcon icon={faMapPin} />{/\S/.test(album.city) ? album.city + ", " : ""}{album.state_name ? album.state_name : ""} {album.country_name ? album.country_name : ""}
                        </Link>
                    </div>
                    <div className='metadata-item'><FontAwesomeIcon icon={faCamera} />{album.model}</div>
                </div>
            </div>}
            <div className={view === 'list' ? 'container photo-gallery-list' : 'container photo-gallery-grid'}>
                {album && album.images.map((photo) => {
                    return (
                        <PhotoTile key={photo.id} photo={photo} onClick={() => setSelectedPhoto(photo)} />
                    )
                })}
                {selectedPhoto && <Lightbox photo={selectedPhoto} onClose={() => setSelectedPhoto(null)} />}
            </div>
        </div>
    )
}

export default Album