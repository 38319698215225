import React from "react";
import GoogleMap from 'google-maps-react-markers'
import Marker from './Marker'
import mapOptions from '../../static/map-options.json'
import { useEffect, useRef, useState } from 'react'
import '../../styles/App.css';
import LocationTile from '../../components/content/LocationTile';


const Map = () => {
  const [locations, setLocations] = useState([])
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)
  const [mapBounds, setMapBounds] = useState({})
  const [highlighted, setHighlighted] = useState(null)

  useEffect(() => {
        fetch("https://ksheth.herokuapp.com/getAllLocations")
        .then(response => response.json())
        .then(data => setLocations(data))
    }, [])

  /**
	 * @description This function is called when the map is ready
	 * @param {Object} map - reference to the map instance
	 * @param {Object} maps - reference to the maps library
	 */
	// eslint-disable-next-line no-unused-vars
	const onGoogleApiLoaded = ({ map, maps }) => {
		mapRef.current = map
		setMapReady(true)
	}

  const onLocationSelected = (e, location) => {
		setHighlighted(location)
    mapRef.current.setCenter({lat: parseFloat(location.lat), lng: parseFloat(location.lng)})
    mapRef.current.setZoom(13)
	}

  const onMapChange = () => {
	}


  return (
      <div className='container container-fullscreen-map'>
        <div className='container container-map'>
            {locations.length > 0 && 
            <div style={{width: '100%', height: '100%'}}>
              <GoogleMap
                apiKey="AIzaSyDQjCAvCW9hQGwn4zg8uJJQrZiArQ-5wcQ"
                defaultCenter={{ lat: 40.7421853, lng: -74.00578589999 }}
                defaultZoom={3}
                options={mapOptions}
                mapMinHeight="100px"
                onGoogleApiLoaded={onGoogleApiLoaded}
                onChange={onMapChange}
              >
                {locations.map((location) => (
                  <Marker key={location.id} lat={parseFloat(location.lat)} lng={parseFloat(location.lng)} markerId={location.id} onClick={onLocationSelected} location={location} isSelected={highlighted && highlighted.id === location.id}/>
                ))}
              </GoogleMap>
            </div>}
        </div>
        <div className='container container-locations'>
            {locations && locations.map((location) => {
                return (
                    <LocationTile key={location.id} markerId={location.id} location={location} onClick={onLocationSelected} isSelected={highlighted && highlighted.id === location.id} />
                )
            })}
        </div>
    </div>
  )
}

export default Map