import React from 'react';
import '../styles/App.css';
import Map from '../components/content/Map';

const Places = () => {

    return (
        <Map />
    )
}

export default Places