import React from 'react';
import '../../styles/App.css';
import MediaQuery from 'react-responsive';
import BarNav from './BarNav';
import BurgerNav from './BurgerNav';

const Nav = () => {
    return (
        <div>
            <MediaQuery minWidth={599}>
                <BarNav/>
            </MediaQuery>
            <MediaQuery maxWidth={599}>
                <BurgerNav/>
            </MediaQuery>
        </div>
    )
}

export default Nav