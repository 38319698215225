import React, { useState, useContext } from 'react';
import { AuthContext } from '../auth/AuthProvider';
import '../../styles/App.css';
import NavButton from '../../styles/icons/nav_icon.svg';

const BurgerNav = () => {
    const [isOpen, setIsOpen] = useState(0)
    const { isAuthenticated, logout } = useContext(AuthContext);

    return (
        <div className={"navbar navbar-collapse " + (isOpen ? "navbar-open" : "")}>
            <div className="container" style={{alignItems: 'center'}}>
                <div className="nav-group">
                    <a href="/"><h2>Kishan</h2></a>
                </div>
                <div className="nav-group">
                    <button className="button button-nav-expand" onClick={() => setIsOpen(!isOpen)}>
                        <img src={NavButton} alt="Navigation Toggle"></img>
                    </button>
                </div>
            </div>
            <div className="navbar-collapse-content">
                <div className="nav-group">
                    <a href="/about" className="nav-item">About</a>
                    <a href="/gallery" className="nav-item">Gallery</a>
                    <a href="/places" className="nav-item">Places</a>
                    {isAuthenticated ? 
                        <button className="nav-item button" onClick={logout}>Logout</button> :
                        <a href="/login" className="nav-item">Friends</a>}
                </div>
            </div>
        </div>
    )
}

export default BurgerNav