import React from 'react'
import './styles/App.css';
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from './components/auth/AuthProvider';
import Nav from './components/navigation/Nav';
import AppRoutes from './pages/AppRoutes';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Nav></Nav>
          <AppRoutes />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;